import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import './scss/style.scss';
import ThemeContext from './Context/AppContext';
import './loader.css'
import { useEffect } from 'react';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)



// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

function App() {

  const [LoaderMain, SetLoaderMain] = useState(false);
  function toggleLoad() {
    SetLoaderMain(visible => !visible);
  }
  const GlobalState = {
    LoaderMain,
    toggleLoad
  }

  // const [ isLogin,setIsLogin] = useState(false)
  document.addEventListener("wheel", function (event) {
    if (document.activeElement.type === "number") {
      document.activeElement.blur();
    }
  });
  
  return (
    <>
      <Router>
        <React.Suspense fallback={loading}>
          <ThemeContext.Provider value={GlobalState}>
            <Switch>
              <Route isAuth={localStorage.getItem("isLogin")} exact path="/login" name="Login Page" render={props => <Login {...props} />} />
              <ProtectedRoute isAuth={localStorage.getItem("isLogin")} exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
              <ProtectedRoute isAuth={localStorage.getItem("isLogin")} exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
              <ProtectedRoute isAuth={localStorage.getItem("isLogin")} path="/" name="Home" render={props => <TheLayout {...props} />} />
            </Switch>
          </ThemeContext.Provider>
        </React.Suspense>
      </Router>
      <section id="myModal" className="modalloader" style={{ display: LoaderMain ? 'block' : 'none' }}>
        <div className='modal_loader'>
          <Image src={'/Images/khiladi-adda-loader.gif'} />
        </div>
      </section>
    </>
  );
}

export default App;
